
















































































































































































import Ueditor from "@/components/Ueditor/index.vue";
import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  FundNonCashDonationRecordDto,
  SelectListItem,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  components: {
    AbSelect,
    Ueditor,
    MultipleUploadFile,
    SimpleUploadImage,
  },
})
export default class CreateFundNonCashDonation extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: FundNonCashDonationRecordDto = {
    id: 0,
    donationType: 1,
  };
  contactType: any = [
    {
      text: "手机",
      value: 1,
    },
    {
      text: "邮箱",
      value: 2,
    },
    {
      text: "两种皆可",
      value: 3,
    },
  ];

  donatorType: SelectListItem[] = [];

  items: any = [];
  btnDisabled = false;

  created() {
    api.enumService
      .getValues({
        typeName: "DonatorType",
      })
      .then((res) => {
        this.donatorType = res;
      });

    if (this.$route.params.id) {
      api.fundNonCashDonation
        .get({id: Number(this.$route.params.id)})
        .then((res) => {
          this.form = {...res};
          if (this.form && this.form.nonCashDonationItemJson) {
            this.items = JSON.parse(this.form.nonCashDonationItemJson);
          }
        });
    }
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form.donatorType === "Personal") {
          this.form.name = this.form.company;
        }
        if (this.form!.id) {
          this.form.nonCashDonationItemJson = JSON.stringify(this.items);
          await api.fundNonCashDonation.update({
            body: this.form,
          });
        } else {
          this.form.nonCashDonationItemJson = JSON.stringify(this.items);
          await api.fundNonCashDonation.create({
            body: this.form,
          });
        }
        this.$router.back();
        this.$message.success("操作成功");
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  addRow() {
    let obj = {
      Content: undefined,

      Count: undefined,

      Unit: undefined,

      Money: undefined,
    };
    this.items = [...this.items!, obj];
  }

  deleteRow(index: number) {
    if (this.items!.length === 1) {
      return;
    }
    this.items!.splice(index, 1);
  }

  cancel() {
    this.$router.back();
  }

  formRules = {
    donatorType: [
      {
        required: true,
        message: "捐赠方类型必填",
        trigger: "blur",
      },
    ],
    company: [
      {
        required: true,
        message: "捐赠方名称必填",
        trigger: "blur",
      },
    ],
    name: [
      {
        required: true,
        message: "联系人姓名必填",
        trigger: "blur",
      },
    ],
    telephone: [
      {
        required: true,
        message: "手机号码必填",
        trigger: "blur",
      },
    ],
    contactType: [
      {
        required: true,
        message: "希望联系方式必填",
        trigger: "blur",
      },
    ],
    email: [
      {
        message: "请填写正确的邮箱地址",
        type: "email",
        trigger: "blur",
      },
    ],
  };
}
